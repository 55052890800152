// components/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => (
   <footer>
      <div className="logo">
         <Link to="/">
            <img src="assets/img/logo.png" alt="Logo" />
         </Link>
         <p>Port Skins</p>
      </div>
      <nav aria-label="footer">
         <Link to="/">Главная</Link>
         <Link to="/about">О нас</Link>
         <Link to="/privacy">Конфиденциальность</Link>
         <Link to="/terms">Правила</Link>
      </nav>
      <p className="footer-text">© Copyright 2024 All Rights Reserved.</p>
   </footer>
);

export default Footer;
