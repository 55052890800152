// Terms.js
import React from 'react';

const Terms = () => (
  <div>
    <div className="description">
      <p className="title">Пользовательское соглашение <span className="name">Port Skins</span></p>
    </div>
    <p>Используя наш сайт, вы автоматически принимаете следующие положения и условия.</p>

    <h2 className='terms-heading'>Основные термины и определения</h2>
    <ul className='terms-list'>
      <li>Веб-сайт — Port Skins</li>
      <li>Вы — лицо, использующее сайт</li>
      <li>Услуги — это услуги, предоставляемые через этот веб-сайт.</li>
    </ul>

    <h2 className='terms-heading'>1. Общая информация</h2>
    <p>
      Мы прилагаем все возможные усилия для защиты этого веб-сайта от вредоносных программ, вирусов и т. д. Однако мы не можем полностью гарантировать их отсутствие. Если вы что-либо загружаете с сайта, вы делаете это на свой страх и риск. Весь контент на сайте является собственностью разработчика игры или лицензиаров и защищен международными законами об авторских правах.
    </p>

    <h2 className='terms-heading'>2. Возможный риск</h2>
    <p>
      Согласно Условиям и положениям большинства онлайн-игр виртуальные игровые предметы нельзя покупать или продавать. В этом случае провайдер имеет право заблокировать ваш аккаунт из-за нарушения соглашений об условиях обслуживания компаний, предоставляющих игровые услуги и обмен предметами. Сайт не несет ответственности за любые действия, предпринятые в отношении вашего игрового аккаунта после транзакции. Сайт не возместит вам средства, если ваш игровой аккаунт будет заблокирован.
    </p>

    <h2 className='terms-heading'>3. Утерянные и украденные предметы</h2>
    <p>
      Вы несете единоличную ответственность за несанкционированный доступ к вашему аккаунту, в том числе с помощью ключа API Steam. Сайт не заменит услуги или продукты, если они будут украдены или утеряны после транзакции. Это также касается частично выполненных соглашений.
    </p>

    <h2 className='terms-heading'>4. Спорные вопросы</h2>
    <p>
      Вы не можете отменить соглашение самостоятельно после первого подтверждения в Steam. Соглашение может быть расторгнуто в одностороннем порядке только с одобрения службы поддержки без объяснения причин. Сайт обязуется выплатить сумму, указанную в счете-фактуре. Если это невозможно, могут быть запрошены альтернативные платежные реквизиты. Если альтернативные способы не предоставлены, сайт может по своему усмотрению вернуть товары. Если оплата успешно отправлена, товары не возвращаются. Жалобы можно отправлять на support@port.skin. Наша команда рассмотрит и ответит как можно скорее. Для виртуальных услуг и товаров защита от третьих лиц недоступна. Для подтверждения транзакций используется внутренняя история торговли. Если транзакция отмечена как завершенная, она считается завершенной.
    </p>

    <h2 className='terms-heading'>5. Услуга условного депонирования</h2>
    <p>
      Вы не можете использовать услугу условного депонирования сайта. Если вас подозревают в использовании сайта в качестве услуги условного депонирования, средства могут быть удержаны на 72 часа для проведения расследования. После расследования будет принято решение о дальнейшем сотрудничестве. Веб-сайт не несет ответственности, когда продавцы предоставляют информацию третьим лицам, а продавцы отказываются от прав требовать исполнения соглашения.
    </p>

    <h2 className='terms-heading'>6. Авторские права</h2>
    <p>
      Торговые марки и авторские права принадлежат их соответствующим владельцам. Контент на сайте является собственностью веб-сайта. Программное обеспечение принадлежит сайту или его поставщикам.
    </p>

    <h2 className='terms-heading'>7. Возмещение ущерба</h2>
    <p>
      Вы соглашаетесь возместить и оградить Веб-сайт, его подрядчиков и поставщиков контента от любых претензий и расходов, включая гонорары адвокатов, возникающих в связи с прекращением. Вы несете ответственность за распространение контента на сайте.
    </p>

    <h2 className='terms-heading'>8. Средства правовой защиты</h2>
    <p>
      Это соглашение не предоставляет прав или средств правовой защиты по закону никакому лицу, кроме сторон этого соглашения.
    </p>

    <h2 className='terms-heading'>9. Автономия</h2>
    <p>
      Если какая-либо часть настоящего Соглашения будет признана недействительной или неисполнимой, она должна толковаться в соответствии с применимым законодательством, чтобы наилучшим образом отразить намерение Сайта. Другие положения остаются в силе.
    </p>
  </div>
);

export default Terms;

